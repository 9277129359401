import logoWhite from '../assets/logo-white.svg'

function FooterSection() {
    return (
        <div id="footer">
            <div className="bg-gray-800 w-full py-8">
                <div className="w-3/4 m-auto text-white tracking-wider font-bold uppercase">
                    <div className="text-center mb-5 pb-10 border-b-2">
                        <div className="text-xl">Covering London</div>
                        <img className="w-4/5 sm:w-1/3 inline-block" src={logoWhite} alt=""/>
                    </div>
                    <div className="md:flex items-center text-center">
                        <div className="sm:text-2xl sm:flex-grow md:text-left">
                            <a href="tel:07944997599">07944 99 75 99</a>
                        </div>
                        <div className="sm:text-2xl">
                            <a href="mailto:info@masonswaste.co.uk">INFO@MASONSWASTE.CO.UK</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterSection;