import nhslogo from '../assets/NHS-Logo.svg'
import Masonry from 'masonry-layout';
import React, {useState, useEffect} from 'react';

// const Colcade = require('colcade');

    // let grid = document.querySelector('.grid');
    // let colc = new Colcade( grid, {
    //     columns: '.grid-col',
    //     items: '.grid-item'
    // });
 

const quoteSvg = <svg className="absolute opacity-25 inset-0" stroke="currentColor" fill="#68d391" strokeWidth="0" viewBox="0 0 512 512" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg"><path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path></svg>
function ReviewTab(props) {
    return (
        <div className="grid-item-m bg-white py-6 px-6 rounded-md shadow-xl relative overflow-hidden">
            {quoteSvg} 
            <p className="text-grey-700 font-semibold mb-3 pt-2">
                {props.text}
            </p>
            <p className="">
                - {props.name}
            </p>
        </div>
    )
}

let msnrySty
function ReviewSection() {
    let [masonry, setMasonry] = useState(null); 
    useEffect(() => {
        let grid = document.querySelector('.grid-m');
        let msnry = new Masonry( grid, { 
            itemSelector: '.grid-item-m', 
            columnWidth: '.grid-sizer',
            gutter: '.gutter-sizer',
            percentPosition: true 
        });
        msnry.layout();
    })
    return (
        <div className="bg-gray-200 w-full py-10">
            <h3 className="mb-10 text-center text-3xl tracking-tight leading-10 font-bold text-gray-800 sm:leading-none">
                Customer reviews
            </h3>
            <div className="grid-m w-3/4 m-auto flex flex-wrap md:flex-no-wrap">
                <div className="grid-sizer"></div>
                <div className="gutter-sizer"></div>
                <ReviewTab text="Thank you so much came to my home with 10 minutes. I will be using this service again I will recommend you to others also." name="Sym"/>
                <ReviewTab text="Come to clear my garden waste within an hour of calling. Fast, polite and professional. Will be back" name="Martin Mitchell"/>
                <ReviewTab text="Fast, efficient, friendly - 100% recommend." name="Yasemin Onur"/>
                <ReviewTab text="The OAP service was top notch, Very professional And highly recommended." name="Darren"/>
                <ReviewTab text="Great Service" name="Peter"/>
                <ReviewTab text="Very efficient, will be returning" name="Sammy"/>
                <ReviewTab text="Well priced services" name="Tim"/>
                <ReviewTab text="Came to collect my home waste within hours, good service" name="Sarah"/>
            </div>
            <a className="button text-white bg-green-500 hover:bg-green-600 w-1/3 sm:w-1/5 block rounded mt-4 py-4 m-auto text-center font-bold focus:outline-none focus:border-green-700 transition duration-150 ease-in-out" href="mailto:info@masonswaste.co.uk?subject=Masonswaste Review">Leave a Review</a>
            <div className="m-auto w-3/4 py-4 text-center">
                <div className="inline-block text-2xl text-center tracking-wider font-black bg-blue-100 border-4 border-blue-600 border-dashed text-blue-700 mt-8 px-4 py-3 rounded uppercase">
                    10% Off For OAP's and <img className="inline-block h-6" src={nhslogo} style={{marginTop: '-5px'}} /> Staff
                </div>
            </div>
        </div>
    )
}

export default ReviewSection;