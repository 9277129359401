import logo from '../assets/logo.svg';
import frontimg from '../assets/mwfront.jpg';

function HeaderSection() {
    return (
<div className="relative bg-white overflow-hidden">
  <div className="max-w-screen-xl mx-auto">
    <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
      <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>
      <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
        <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
          <div className="flex items-center flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="#" aria-label="Home">
                <img className="h-3 w-auto" src={logo} alt="Logo" />
              </a>
            </div>
          </div>
          <div className="block sm:ml-10">
            <a href="tel:07944997599" className="text-xs sm:text-base font-medium text-gray-800 font-extrabold hover:text-gray-900 transition duration-150 ease-in-out">07944 99 75 99</a>
          </div>
        </nav>
      </div>

      <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div className="sm:text-center lg:text-left">
          <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-800 sm:text-5xl sm:leading-none md:text-6xl">
            Same day 
            <br />
            <span className="text-green-600">Waste Removal</span>
          </h2>
          <p className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Contact us today (<a href="tel:07944997599" className="font-bold hover:text-gray-900 transition duration-150 ease-in-out">07944 99 75 99</a>) and we will provide you with a competitive quote for all your waste services. We can collect any type of waste including cardboard, general, recycling, glass and many more.
          </p>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <a href="tel:07944997599" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
  <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
    <img className="h-64 w-full object-cover lg:w-full lg:h-full" src={frontimg} alt="" style={{minHeight: '400px'}} />
  </div>
</div>
    )
  }
  export default HeaderSection