// import logo from './logo.svg';
import './assets/main.css';
import { Helmet } from 'react-helmet';
import HeaderSection from './Components/HeaderSection';
import FooterSection from './Components/FooterSection';
import ReviewSection from './Components/ReviewSection';
import skipimg from './assets/mwskip.jpg';


const fstyle = {
  backgroundImage: 'url(' + skipimg + ')',
  height: '330px'
}
const btnSty = 'inline-block bg-green-200 rounded-full px-6 py-3 text-xl font-semibold text-green-900 mr-2 mb-2';
function App() {
  return (
    <div className="overflow-hidden font-sans bg-gray-100">
      <HeaderSection />
      <div className="m-auto md:w-3/4 py-8">
        <div className="lg:max-w-sm w-full lg:max-w-full lg:flex">
          <div style={fstyle} className="h-64 lg:w-1/2 flex-none bg-cover bg-center rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" title=""></div>
          <div className="bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
            <div className="">
              <div className="text-gray-900 font-bold text-xl mb-2"> Our skip size is perfect for home removal waste, 
              giving you a great value for money.</div>
              <p className="text-gray-700 text-xl">
                          They are easy 
              to load unlike conventional cage skips making it a 
              perfect solution for builders. We have multiple trucks 
              avalible for hire.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <h3 className="mb-8 text-center text-3xl tracking-tight leading-10 font-bold text-gray-800 sm:leading-none">
                Our services
            </h3>
          <div className="m-auto flex flex-wrap lg:flex-no-wrap mb-8 px-8">
                <div className="mr-4 mb-4">
                    <p className="font-semibold text-xl mb-4">
                        House Clearance
                    </p>
                    <p>
                        Whether you’re decluttering, renovating or simply getting rid of your rubbish, we’re licenced to remove all kinds of domestic waste from homes in Greater London. We can even uninstall fitted kitchens, bathrooms and furniture. Quick, clean and reliable service.
                    </p>
                </div>
                <div className="mr-4 mb-4">
                    <p className="font-semibold text-xl mb-4">
                      Builders’ Clearance 
                    </p>
                    <p>
                    As an Environment Agency registered company, we’re authorised to remove, transport and dispose of all waste from building sites across London. We offer a quick, efficient and responsible builders’ clearance service with affordable prices and experienced two-person teams.
                    </p>
                    </div>
                <div className="mr-4 mb-4">
                    <p className="font-semibold text-xl mb-4">
                      Garden Clearance 
                    </p>
                    <p>
                    We’re licenced to remove and recycle all your organic and non-organic garden waste. This includes everything from tree trunks and old greenhouses to grass cuttings and plant pots. We offer a fast, reliable and tidy service by fully trained and well-equipped teams.</p>
                </div>
                <div className="mr-4 mb-4">
                    <p className="font-semibold text-xl mb-4">
                      Office Rubbish Removal
                    </p>
                    <p>
                    We remove, dispose of and recycle all forms of commercial waste, including office rubbish, retail packaging and clutter, broken and outdated equipment and more. Fully licenced, our experienced teams provide a quick, clean and reliable rubbish removal service at low cost.</p>
                </div>
            </div>
          <div className="text-center">
            <span className={ btnSty }>Home Waste Disposal</span>
            <span className={ btnSty }>Furniture Removal</span>
            <span className={ btnSty }>Plastic Recycling</span>
            <span className={ btnSty }>Bricks Recycling</span>
            <span className={ btnSty }>Bricks and Insert Waste Recycling</span>
            <span className={ btnSty }>Clothing and Textile Recycling</span>
          </div>
        </div>
      </div>
      <ReviewSection />
      <FooterSection />
    </div>
  );
}

export default App;